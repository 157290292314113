import { Role } from '@/_helpers/role'

export default [
  {
    path: '/access-control',
    name: 'misc-not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
