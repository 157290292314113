import { Role } from '@/_helpers/role'

export default [
  {
    path: '/goals',
    name: 'goals',
    component: () => import('@/views/goals/Goals.vue'),
    meta: {
      authorize: [Role.Admin],
    },
  },
]
