import { Role } from '@/_helpers/role'

export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      layout: 'boxed',
      authorize: [Role.Admin, Role.Employee, Role.Screen],
    },
  },
]
