import Vue from 'vue'
import VueRouter from 'vue-router'
import { getHomeRouteForLoggedInUser, getUserData } from '@/auth/utils'
import pages from "@/router/routes/pages";
import others from "@/router/routes/others";
import dashboard from "@/router/routes/dashboard";
import goals from "@/router/routes/goals";
import {Role} from "@/_helpers/role";

const root = getUserData() ? getHomeRouteForLoggedInUser(getUserData().role_id) : getHomeRouteForLoggedInUser(-1)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        authorize: [Role.Employee, Role.Screen],
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/tv',
      name: 'TV',
      component: () => import('@/views/tv_page/TvPage.vue'),
      meta: {
        layout: 'full',
      },
    },
    { path: '/', redirect: root },
      ...pages,
      ...others,
      ...dashboard,
      ...goals,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authorize = to.meta.authorize
  const currentUser = getUserData()

  if (authorize) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      return next({ path: '/' })
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(currentUser.role_id)) {
      // role not authorised so redirect to home page
      return next({ name: 'misc-not-authorized' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
